<template>
    <section>
        <header-page :title="tema.nombre" ruta-atras="responsable.cursos.temas" :params="params" />
        <!-- <header-page :title="tema.nombre" /> -->
        <div class="row mx-2 border-bottom-edit mt-3">
            <router-link
            v-for="(ruta , r) in rutas"
            :key="r"
            class="col-auto py-3"
            :class="$route.name == ruta.name ? 'current-tab f-20' : 'tab f-18'"
            :to="{name: ruta.name}"
            >
                {{ ruta.titulo }}
            </router-link>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 300px)">
            <router-view />
        </div>
    </section>
</template>

<script>
import CursoDetalle from "~/services/cursos/detalle_responsable";

export default {
    data(){
        return{
            rutas: [
                { titulo: 'Tema', name: 'responsable.curso.detalle.temas'},
                { titulo: 'Calificación', name: 'responsable.curso.detalle.calificaciones'}
            ],
            params: {
                id_curso: null
            },
            tema: {
                nombre: '',
                id_curso: null,
            }
        }
    },
    computed: {
        idTema(){
            return this.$route.params.id_tema
        },  
    },
    mounted(){
        this.getTema()  
    },
    methods: {
        calificacion(){
            this.$refs.Calificacion.toggle();
        },
        async getTema(){
            try {
                const { data: { data } } = await CursoDetalle.getTema(this.idTema)
                this.tema = data.tema
                this.params.id_curso = data.tema.id_curso

            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.border-bottom-edit{ border-bottom: 1px solid #707070; }
</style>