<template>
    <section v-loading="loading" class="calificadas">
        <template v-if="!calificadas">
            No hay entregas calificadas
        </template>
        <template v-else>
            <div class="container-fluid mt-4">
                <div class="container mx-auto">
                    <div class="row justify-content-lg-end my-2">
                        <div class="col-lg-4 offset-8">
                            <el-input v-model="search" placeholder="Buscar por nombre de entrega calificada">
                                <i slot="suffix" class="icon-search f-20 mt-3" />
                            </el-input> 
                        </div>
                    </div>
                    <div class="row justify-content-lg-center">
                        <div v-for="(entrega, e) in entregas" :key="e" class="col-lg-3 br-12 bs-card my-3 mx-2 cr-pointer" @click="abrirModalVer(entrega)">
                            <div class="container">
                                <div class="row justify-content-lg-between my-2">
                                    <span><p class="f-20 f-600">{{ entrega.titulo }}</p></span>
                                    <span><i v-show="entrega.hecha.califica_nota >=3" class="icon-checkbox-marked-circle-outline f-20 text-green" /></span>
                                </div>
                                <div class="row justify-content-lg-between my-4">
                                    <div class="col px-0">                                   
                                        <span><i class="icon-email-send-outline text f-20 mr-3 text-blue" /></span>
                                        <p v-text="formatearFecha(entrega.hecha.califica_fecha, 'DD/ MMM /YYYY')" />
                                        <p v-text="formatearFecha(entrega.hecha.califica_fecha, 'hh:mm A')" />
                                    </div>
                                    <span><p class="f-20" :style="`color: ${(entrega.hecha.califica_nota >= 3)? 'green': 'red'}`">5.0</p></span>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-3 br-2 bs-card my-3 mx-2 bg-grade-card cr-pointer" @click="abrirModalSubir">
                            <div class="container">
                                <div class="row justify-content-lg-between my-2">
                                    <span><p class="f-20 f-600">Subir entrega calificada</p></span>
                                </div>
                                <div class="row justify-content-lg-center my-4">
                                    <span><i class="icon-cloud-upload my-auto f-60 text-df" /></span>
                                    <span class="my-auto"><p class="f-22 text-muted">Subir entrega</p></span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </template>
        <modal-entregas-calificadas ref="modalVer" />
        <modal-subir-entrega-calificada ref="modalSubir" />
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';


const vuexRoute = 'responsable/entregas'
export default {
    components: {
        modalEntregasCalificadas: () => import('./partials/modalEntregasCalificadas.vue'),
        modalSubirEntregaCalificada: () => import('./partials/modalSubirEntregaCalificada.vue'),
    },
    data(){
        return {
            search: '',
            calificadas:{
                titulo: 'Entrega calificada',
                fecha: '22/Ene/2021',
                nota: 5.0,
            },
            entregas: [],
            loading:false,
            data:[],
        }
    },
    mounted(){
        this.listarEntregas()
    },
    methods: {
        async listarEntregas(){
            let id_curso = this.$route.params.id_curso
            const { data } = await axios.get(`lector/get-total-entregas-curso/${id_curso}`)
            this.entregas = data.data.entregas
        },
        abrirModalSubir(){
            this.$refs.modalSubir.toggle()
        },
        abrirModalVer(entrega){
            //console.log()
            this.$refs.modalVer.toggle(entrega)
        }
    }
}
</script>

<style lang="scss" scoped>
    .calificadas {
        .bs-card{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #DFDFDF;
        }
    }
</style>
