<template>
    <section class="px-5">
        <div class="row mx-0 my-3">
            <div class="col-xl-3 px-0 col-md-3 d-flex flex-column pt-2 border-right-edit">
                <p class="text-gris f-18 f-600 text-center">
                    Promedio de <br /> calificación
                </p>
                <p class="text-general f-18 f-35 f-600 text-center py-4">
                    {{ tema.calificacion }}
                </p>
            </div>
            <div class="col-xl-3 col-md-3 d-flex flex-column pt-2 border-right-edit">
                <p class="text-gris f-18 f-600 text-center">
                    Total de <br /> calificaciones
                </p>
                <p class="text-general f-18 f-35 f-600 text-center py-4">
                    {{ tema.calificaciones_count }}
                </p>
            </div>
            <div class="col py-2">
                <p class="f-600 text-gris f-18">
                    Filtro de Calificaciones
                </p>
                <p class="text-gris f-15">
                    Seleccione un valor para filtrar
                </p>
                <div class="row mx-0 my-3">
                    <div 
                    v-for="(data, d) in filtros" 
                    :key="d" 
                    class="card-calificacion filtro-activo text-center d-flex flex-column j-center mx-2 my-2 cr-pointer"
                    :class="filtro_actual == data.item ? 'filtro-activo' : 'filtro'"
                    @click="filtro_actual != data.item ? filtro_actual = data.item : filtro_actual = null"
                    >
                        <p class="f-25 f-600 first" v-text="data.item" />
                        <hr class="border mx-2" />
                        <p class="f-20 second" v-text="data.cantidad" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-auto f-18 text-gris f-600">
                Calificaciones
            </div>
            <div class="col-xl-3 col-lg-4 ml-auto">
                <el-input v-model="buscar" class="w-100" suffix-icon="icon-search f-17 text-general" placeholder="Buscar" />
            </div>
            <div class="col-12 px-0">
                <div v-for="(calificacion,c) in filtroCalificaciones.filter(el => buscar == '' || el.usuario.nombre.toLowerCase().includes(buscar.toLowerCase()))" :key="c" class="row mx-0 mt-4 mb-2 border-bottom">
                    <div class="col-auto pr-0">
                        <img :src="calificacion.usuario.imagen" class="obj-cover rounded-circle border-gris" width="55" height="55" />
                    </div>
                    <div class="col px-1">
                        <div class="row mx-0">
                            <p class="col-12 tres-puntos"> {{ calificacion.usuario.nombre }} </p>
                            <p class="col tres-puntos"> {{ formatearFecha(calificacion.calificacion_fecha, 'DD / MMM / YYYY') }} </p> <p class="col"> {{ formatearFecha(calificacion.calificacion_fecha, 'hh:mm A') }} </p>
                        </div>
                    </div>
                    <div class="col-1 px-1 text-gris">
                        <el-tooltip placement="bottom" content="Calificación" effect="light">
                            <i class="f-18 text-yellow icon-star" />
                        </el-tooltip>
                        {{ calificacion.calificacion }}
                    </div>
                    <div class="col pb-2">
                        {{ calificacion.calificacion_comentario }}
                    </div>
                </div>                
            </div>
        </div>
    </section>
</template>

<script>
import CursoDetalle from "~/services/cursos/detalle_responsable";

export default {
    data(){
        return {
            buscar: '',
            url_image: 'https://img.freepik.com/foto-gratis/retrato-joven-sonriente-gafas_171337-4842.jpg?size=626&ext=jpg',
            filtros: [
                {
                    item: 1,
                    cantidad: 0
                },
                {
                    item: 2,
                    cantidad: 0
                },
                {
                    item: 3,
                    cantidad: 0
                },
                {
                    item: 4,
                    cantidad: 0
                },
                {
                    item: 5,
                    cantidad: 0
                }
            ],
            filtro_actual: null,
            calificaciones: [],
            calificacionesOriginal: [],
            tema:{
                calificacion:0,
                calificaciones_count:0,
            }
        }
    },
    computed: {
        filtroCalificaciones(){
            let calificaciones
            if (this.filtro_actual){
                calificaciones = this.calificaciones.filter(el => el.calificacion == this.filtro_actual)
            } else {
                calificaciones = this.calificaciones
            }
            return calificaciones
        },
        idTema(){
            return this.$route.params.id_tema
        },  
    },
    mounted(){
        this.getTemaCalificaciones()  
    },
    methods: {
        async getTemaCalificaciones(){
            try {
                const { data: { data } } = await CursoDetalle.getTemaCalificaciones(this.idTema)
                this.filtros = data.calificaciones
                this.calificaciones = data.tema.calificaciones
                this.tema.calificacion = data.tema.calificacion
                this.tema.calificaciones_count = data.tema.calificaciones_count
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.card-calificacion{
    width: 55px;
    height: 123px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
}
.filtro-activo{
    background-color: #00125A;
    color: #FFF;
    hr{
        border: 1px solid #FFF;
    }
}
.filtro{
    border: 1px solid #DFDFDF;
    background-color: #FFFFFF;
    .first{
        color: var(--color-general) !important;
    }
    .second{
        color: var(--color-grey) !important;
    }
}
.border-left-edit{
    border-left: 2px solid #B0B0B0;
}
.border-right-edit{
    border-right: 2px solid #B0B0B0;
}
</style>