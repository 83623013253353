<template>
    <section>
        <template v-if="temas.length">
            <div class="row mx-5 mt-2">
                <div class="col f-600 text-gris f-18" />
                <div class="col-3 text-center f-18">
                    <el-tooltip placement="bottom" content="Visualización" effect="light">
                        <i class="icon-eye text-general f-20" />
                    </el-tooltip>
                </div>
                <div class="col-3 px-4 text-right f-18" />
            </div>        
            <div v-for="(tema,t) in temas" :key="t" class="row mx-5 bg-whitesmoke br-12 py-2 border-edit my-2 cr-pointer text-gris" @click="verDetalleTema(tema)">
                <div class="col f-600 text-gris f-18 tres-puntos">
                    {{ tema.nombre }}
                </div>
                <div class="col-3 text-center f-18">
                    {{ tema.interacciones_count }}
                </div>
                <div class="col-3 text-right f-18">
                    <i class="icon-star text-yellow" /> {{ tema.calificacion ? tema.calificacion : '-' }}
                </div>
            </div>
        </template>
        <template v-else>
            <p>aqui no hay temas</p>
        </template>
    </section>
</template>

<script>
import CursoDetalle from "../../services/cursos/detalle_responsable";

export default {
    data(){
        return {
            temas:[]
        }
    },
    computed: {
        idCurso(){
            return this.$route.params.id_curso
        },  
    },
    mounted(){
        this.getCurso()  
    },
    methods: {
        calificacion(){
            this.$refs.Calificacion.toggle();
        },
        async getCurso(){
            try {
                const { data: { data } } = await CursoDetalle.getTemasCurso(this.idCurso)
                this.temas = data.temas
            } catch (error){
                this.error_catch(error)
            }
        },
        verDetalleTema(data){
            this.$router.push(
                {
                    name: 'responsable.curso.detalle.temas',
                    params: {
                        id_tema: data.id
                    }
                }
            )
        }
    }
}
</script>

<style lang="scss" scoped>
a:hover{
    color: var(--color-grey) !important;
}
.border-edit{
    border: 1px solid #DFDFDF;
}
</style>