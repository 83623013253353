<template>
    <section>
        <header-page ruta-atras="responsable" :title="_.get(infoCurso, 'nombre', '')">
            <div class="col-auto ml-auto">
                <router-link :to="{name: 'foro'}" class="text-gris">
                    <p class="f-18 text-grtis f-600">Foro <i class="icon-forum f-20 text-general" /></p>
                </router-link>
            </div>
        </header-page>
        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 205px)">
            <div class="row mx-0">
                <div class="col d-flex flex-column justify-content-around">
                    <p>
                        {{ _.get(infoCurso, 'descripcion', '') }}
                    </p>
                    <div class="row mx-0 mt-3">
                        <div class="col-auto px-0 d-middle text-general f-18 mr-3 f-600">
                            Responsables 
                        </div>
                        <div class="col-lg col-xl-5">
                            <div v-for="(user,idx2) in Object.values(infoCurso.responsables)" :key="idx2">
                                <el-tooltip placement="bottom" :content="user.usuario.nombre" effect="light">
                                    <img 
                            
                                    :src="user.usuario.imagen" 
                                    width="34" 
                                    height="34" 
                                    :style="`left:${idx2* 20}px;z-index:${idx2};`"
                                    class="obj-cover rounded-circle border-grey position-absolute"
                                    />
                                </el-tooltip>
                            </div>

                            <div v-show="infoCurso.mayor_cinco" 
                                 class="rounded-circle position-absolute bg-whitesmoke border-grey d-middle j-center" 
                                 :style="`left:${(5*20)}px;`" 
                                 style="width:34px;height:34px;z-index:25;top:0px"
                            >
                                <el-popover
                                width="150px"
                                placement="top"
                                trigger="hover"
                                >
                                    <ul class="p-0 m-0 custom-scroll overflow-auto" style="height: 100px">
                                        <li v-for="( user,index ) in Object.values(infoCurso.responsables).filter((el, index) => {return index > 4})" :key="index" class="p-1">
                                            <p class="f-15">
                                                {{ user.usuario.nombre }}
                                            </p>
                                        </li>
                                    </ul>
                                    <i slot="reference" class="icon-plus f-17 text-general" />
                                </el-popover>
                            </div>
                        </div>
                        <div class="col-auto text-left">
                            <el-tooltip placement="bottom" content="Lectores" effect="light">
                                <div>
                                    <i class="icon-account-circle f-24 text-general" />
                                    {{ _.get(infoCurso, 'lectores', '0') }}
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto text-left">
                            <el-tooltip placement="bottom" content="Entregas" effect="light">
                                <div>
                                    <i class="icon-file-multiple f-24 text-general" />
                                    {{ _.get(infoCurso, 'entregas_count', '0') }}
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="col-auto d-flex flex-column justify-content-around">
                    <el-tooltip placement="bottom" content="Calificación promedio del curso" effect="light">
                        <div class="bg-white br-12 shadow px-3 text-center py-2 border-edit my-2" @click="calificacion">
                            <p class="text-gris f-18 f-600">Calificacíon</p>
                            <p class="f-30 text-general f-600">
                                {{ _.get(infoCurso, 'calificacion', '0') }}
                                <el-tooltip placement="bottom" content="Calificacion promedio del Curso" effect="light">
                                    <i class="icon-information-outline f-17 text-gris" />
                                </el-tooltip>
                            </p>
                        </div>
                    </el-tooltip>
                    <div class="bg-white border-edit shadow br-12 py-2 text-general f-18 cr-pointer text-center my-2 px-3 f-600 d-flex" @click="$router.push({name:'mensajes', params:{id_curso:infoCurso.id}})">
                        <div class="mr-3">
                            <el-badge :value="15" :hidden="true">
                                <i class="icon-message-reply-text f-18" />
                            </el-badge>
                        </div>
                        Mensajes
                    </div>
                </div>
            </div>
            <div class="row mx-3 border-bottom mt-3">
                <router-link
                v-for="(ruta, r) in rutas"
                :key="r"
                :to="{name: ruta.name}"
                :class="$route.name == ruta.name ? 'current-tab f-22' : 'tab f-20'"
                class="col-auto px-2"
                >
                    <template v-if="ruta.cant_entregas">
                        <el-badge :value="ruta.cant_entregas">
                            {{ ruta.titulo }}    
                        </el-badge>
                    </template>
                    <template v-else>
                        {{ ruta.titulo }}
                    </template>
                </router-link>
            </div>
            <div class="px-3">
                <router-view />
            </div>
        </div>
        <!-- Partials -->
        <modal-calificacion ref="Calificacion" :calificacion="parseFloat(infoCurso.calificacion)" :calificaciones="parseFloat(infoCurso.calificaciones)" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
const vuexRoute = 'responsable/entregas'
export default {
    components: {
        modalCalificacion: () => import('./partials/modalCalificacion')
    },
    
    computed: {
        idCurso(){
            return this.$route.params.id_curso
        },
        ...mapGetters({
            infoCurso: `${vuexRoute}/getInfoCurso`,
            rutas: `${vuexRoute}/getRutas`
        }),
    },
    mounted(){
        this.fetchCursos(this.idCurso)  
    },
    methods: {
        ...mapActions({
            fetchCursos: `${vuexRoute}/fetchCursos`,
        }),
        calificacion(){
            this.$refs.Calificacion.toggle();
        },
    }
}
</script>

<style lang="scss" scoped>
.border-edit{
    border: 1px solid #F8F8F8;
}
.border-grey{
    border: 1px solid #707070;
}
</style>