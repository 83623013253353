import axios from 'axios';

const API_PREFIX = 'admin';

const ENDPOINTS = {

    crearCarpeta: (payload) => axios.post(`${API_PREFIX}/carpetas`,payload),
    editarCarpeta: (payload) => axios.put(`${API_PREFIX}/carpetas`,payload),
    listarCarpeta: (payload) => axios.get(`${API_PREFIX}/carpetas/${payload.id_carpeta}`),
    ordenarCursoPosicion: (payload) => axios.put(`${API_PREFIX}/cursos/posicion`, payload),
    eliminarCurso: (payload) => axios.delete(`${API_PREFIX}/carpeta/${payload.id_carpeta}/cursos/${payload.id_curso}`, payload),
    obtenerCursosCarpeta: (payload) => axios(`${API_PREFIX}/carpetas/${payload.id_carpeta}/cursos`),
    eliminarCarpeta: (payload) => axios.delete(`${API_PREFIX}/carpetas/${payload.id_carpeta}`),
    listarNombreCarpetas: () => axios.get(`${API_PREFIX}/carpetas/nombres/todos`),


};

export default ENDPOINTS;
