<template>
    <section 
    class="mis-cursos custom-scroll overflow-auto" 
    :class="$route.name == 'configurar.carpeta.ver' ? '' : 'px-4'" 
    :style="`height:calc(100vh - ${$route.name == 'configurar.carpeta.ver' ? '120' : '236'}px);`"
    >
        <header-page v-if="$route.name == 'configurar.carpeta.ver'" :title="titulo">
            <div class="d-flex align-items-center">
                <div class=" ml-3">
                    <div class="shadow br-12 py-2 text-center text-general px-3 bg-blue text-white cr-pointer" style="width:132px" @click="$router.push({name:'mis-cursos.editar.carpeta', params:{id_carpeta: id_carpeta}})">
                        Editar Carpeta
                    </div>
                </div>
                <div class="col-auto">
                    <div class="shadow br-12 py-2 text-center text-general bg-red text-white px-3 cr-pointer" @click="modalEliminarCarpeta">
                        Eliminar Carpeta
                    </div>
                </div>
                <!-- <div class="col-auto ">
                    <div class="shadow br-12 py-2 text-general bg-general text-white px-3">
                        Crear Curso
                    </div>
                </div> -->
            </div>
            <div class="col-auto p-0">
                <div class="shadow br-12 py-2 text-general bg-general text-white px-3 px-3 cr-pointer" @click="openModalCrearCurso">
                    Crear Curso
                </div>
            </div> 
        </header-page>

        <div class="row mx-0 my-3">
            <div class="col-1 px-5" />
            <div class="col-auto px-0 f-600 f-20 text-gris">
                Curso
            </div>
        </div>
        <router-link v-for="(curso, idx) in cursos" :key="idx" class="row mx-0 pb-3 mb-2 border-bottom" :to="{name: 'responsable.ver', params:{id_curso: curso.id}}">
            <div class="col-1 d-middle j-center pt-1 px-0">
                <div v-show="curso.nuevo" class="bg-purple text-white text-center br-5 py-2 px-3">
                    NUEVO
                </div>
            </div>
            <div class="col px-0">
                <div class="row mx-0 a-center">
                    <div class="col tres-puntos f-18 f-600 text-gris">
                        <span v-if="$route.name !== 'configurar.carpeta.ver'"> 
                            {{ curso.nombre_carpeta }}

                        </span>
                    </div>
                    <el-tooltip placement="bottom" content="Calificacíon promedio" effect="light">
                        <div class="col-auto f-18">
                            <i class="icon-star f-18 text-yellow" /> {{ curso.calificacion }}
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Fecha de actualización" effect="light">
                        <div class="col-3 text-center tres-puntos">
                            <i class="icon-calendar text-general f-20" />
                            {{ formatearFecha(curso.fecha_actualizacion,'D/M/Y') }}
                        </div>
                    </el-tooltip>
                    <div class="col-3 position-relative d-middle j-center">
                        <div v-for="(user,idx2) in curso.usersResponsable" :key="idx2">
                            <el-tooltip placement="bottom" :content="user.usuario.nombre" effect="light">
                                <img 
                            
                                :src="user.usuario.imagen" 
                                width="34" 
                                height="34" 
                                :style="`left:${idx2 * 20}px;z-index:${idx2}; top:-13px`"
                                class="obj-cover rounded-circle border-grey position-absolute"
                                />
                            </el-tooltip>
                        </div>
                        <div v-show="curso.mayorCinco" 
                             class="rounded-circle position-absolute bg-whitesmoke border-grey d-middle j-center" 
                             :style="`left:${5 * 20}px;`" 
                             style="width:34px;height:34px;z-index:25; top:-13px"
                        >
                            <el-popover
                            placement="top" 
                            width="180"
                            trigger="hover"
                            >
                                <ul class="m-0 p-2 custom-scroll overflow-auto" style="height: 120px">
                                    <li v-for="(responsable, index) in curso.usersResponsable.slice(0,4) " :key="index" class="list-masResponsables">
                                        <p>{{ responsable.usuario.nombre }}</p>
                                    </li>   
                                </ul>
                                
                                <i slot="reference" class="icon-plus f-17 text-general" />   
                            </el-popover>
                        </div>
                    </div>
                </div>
                <div class="row mx-0" style="height:30px">
                    <div class="col mt-1">
                        <i class="f-17 text-gris"> 
                            {{ curso.nombre_curso }}
                        </i>
                    </div>
                </div>
            </div>
            <div class="col-auto d-md-flex flex-lg-column ">
                <div class="row mx-0 text-gris a-center j-center">
                    <el-tooltip placement="bottom" content="Lectores" effect="light">
                        <i class="icon-account-circle f-20 text-general" /> 
                    </el-tooltip>
                    {{ curso.cant_lectores }}
                </div>
                <div class="row mx-0 a-center j-center">
                    <el-tooltip placement="bottom" content="Visualizaciones" effect="light">
                        <i class="icon-eye f-20 text-general" />
                    </el-tooltip>
                    {{ curso.cant_visualizaciones }}
                </div>
            </div>
            <!-- <el-tooltip placement="bottom" content="Foro" effect="light">
                <div class="col-auto pt-2 mr-3">
                    <el-badge :value="curso.cant_foros">
                        <i class="icon-forum f-20 text-general cr-pointer" />
                    </el-badge>
                </div>
            </el-tooltip> -->
        </router-link>
        <div v-if="cursos.length <=0" class="row mx-0">
            <div class="col-1 px-5" />
            <div class="col px-0">
                No tiene cursos
            </div>
        </div>
        <modalEliminarCarpeta ref="modalEliminarCarpeta" />
        <openModalCrearCurso ref="modalCrearCurso" />
    </section>
</template>

<script>
import CursosResponsable from '~/services/cursos/responsable'
import Carpeta from '~/services/cursos/carpetas'

export default {
    components:{
        modalEliminarCarpeta: ()=> import('~/pages/misCursos/partials/modalEliminarCarpeta'),
        openModalCrearCurso: ()=> import('~/pages/misCursos/partials/modalCrearCurso')
    },
    data(){
        return {
            cursos:[],
            id_carpeta: parseInt(this.$route.params.id_carpeta),
            titulo: '',
            masResponsables: []
        }
    },
    mounted(){
        if(this.$usuario.rol===1){// lector
            this.listarCursosResponsable()
        }else if(this.$usuario.rol===2 && this.$route.name==='responsable'){ // es admin y en tab responsable
            this.listarCursosResponsable()
        }else{// dentro del configurar
            this.listarCursosCarpetaAdmin()
        }
    },
    methods: { 
        async listarCursosResponsable(){
            try {
                const { data:{ data:{ cursos } } } = await CursosResponsable.obtenerCursosUsuarioResponsable()
                this.cursos = cursos
            } catch (e){
                this.error_catch(e)
            }

        },
        async listarCursosCarpetaAdmin(){
            try {
                const {data:{data}} = await Carpeta.obtenerCursosCarpeta({id_carpeta: this.id_carpeta})
                this.cursos = data.cursos
                console.log(data.cursos)

                this.carpeta = data.carpeta
                this.titulo = `Cursos de la carpeta ${this.carpeta.nombre}`
            } catch (e){
                this.error_catch(e)
            }
        },
        modalEliminarCarpeta(){
            this.$refs.modalEliminarCarpeta.toggle()
        },
        openModalCrearCurso(){
            this.$refs.modalCrearCurso.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
.border-grey{
    border: 1px solid #707070;
}
a{
    color: var(--color-grey) !important;
}
.list-masResponsables{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    // background: red ;
}
</style>