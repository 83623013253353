<template>
    <section>
        <div class="row mx-4 mt-3">
            <div class="col-12 border-bottom py-3">
                <p v-html="tema.contenido" />
            </div>
        </div>
        <div class="row mx-4">
            <div class="col-12 f-600 text-gris f-30 py-3">
                Ítems
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col" />
                    <div class="col-3" />
                    <div class="col-3 text-center">
                        <el-tooltip placement="bottom" content="Visualización" effect="light">
                            <i class="icon-eye f-18 text-general" />
                        </el-tooltip>
                    </div>
                </div>
                <div v-for="(recurso,r) in tema.recursos" :key="r" class="row mx-0 a-center mb-2 border-bottom py-2">
                    <div class="col-auto px-0 cr-pointer" @click="setVisualizar(recurso)">
                        <i :class="`${icon_extension(recurso.archivo)} i f-18 text-general`" />
                    </div>
                    <div class="col f-600 f-18 tres-puntos">
                        {{ recurso.nombre }}
                    </div>
                    <div class="col-auto">
                        <i v-show="recurso.descargable" class="icon-download f-18 cr-pointer text-general" @click="downloadURI(recurso.archivo,recurso.nombre)" />
                    </div>
                    <div class="col-3 text-center">
                        {{ recurso.peso }} MB
                    </div>
                    <div class="col-3 text-center">
                        {{ recurso.interacciones_count }}
                    </div>
                </div>
            </div>
        </div>
        <!-- <visor-pdf ref="vPdf" /> -->
        <modal-video ref="modal_video" />
        <modal-imagen ref="ver_archivo" />
    </section>
</template>

<script>
import CursoDetalle from "~/services/cursos/detalle_responsable";

export default {
    data(){
        return{
            tema: {
                nombre: '',
                contenido: '',
                id_curso: null,
                recursos: []
            }
        }
    },
    computed: {
        idTema(){
            return this.$route.params.id_tema
        },  
    },
    mounted(){
        this.getTemaRecursos()  
    },
    methods: {
        calificacion(){
            this.$refs.Calificacion.toggle();
        },
        async getTemaRecursos(){
            try {
                const { data: { data } } = await CursoDetalle.getTemaRecursos(this.idTema)
                this.tema = data.tema
            } catch (error){
                this.error_catch(error)
            }
        },
        async setVisualizar(row){
            if (this.file_type(row.archivo) == "application/pdf"){
                window.open(`/visor?nombre=${row.archivo_firmado}`,'_blank')
            }else if(this.file_type(row.archivo) == "video/*"){
                this.$refs.modal_video.ModalVideo(row.archivo_firmado)
            }else if(this.file_type(row.archivo) == "image/*"){
                this.$refs.ver_archivo.toggle(row.archivo_firmado)
            }else{
                await this.downloadURI(row.archivo, row.nombre);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>