<template>
    <section v-loading="loading">
        <template v-if="!entregas.length">
            No hay entregas
        </template>
        <template v-else>
            <div class="row mx-0 mt-2 justify-content-end">
                <div class="col-xl-3 col-lg-3 col-md-4">
                    <el-input v-model="busqueda" class="w-100" placeholder="Buscar" suffix-icon="icon-search text-general" />
                </div>
            </div>
            <div v-for="(data, d) in entregasFiltered" :key="d" class="row mx-0 my-3">
                <div class="col-auto px-0">
                    <img :src="data.usuario_foto" class="obj-cover rounded-circle border-gris" width="62" height="62" />
                </div>
                <div class="col px-1">
                    <div class="row mx-0">
                        <p class="col tres-puntos f-18 text-gris px-2" v-text="data.nombre" />
                        <p class="col tres-puntos f-18 text-gris px-2 text-left " v-text="formatearFecha(data.user_fecha)" />
                        <p class="col tres-puntos f-18 text-gris px-2 text-left" v-text="formatearFecha(data.user_fecha, 'HH:mm')" />
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="text-gris f-14 ml-2">
                                Entrega: <i>
                                    {{ data.titulo }}
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 px-2">
                        <div v-for="(cal, c) in data.adjuntos" :key="c" class="etiqueta tres-puntos py-1 px-3 mx-1 my-1 cr-pointer" @click="setVisualizar(cal)" v-text="cal.nombre" />
                    </div>
                </div>
                <div class="col-auto px-2">
                    <el-tooltip effect="light" content="Descargar archivo" placement="top">
                        <i class="icon-download text-general f-20 mx-2 cr-pointer" @click="descargarArchivos(data)" /> 
                    </el-tooltip>
                    <el-tooltip effect="light" content="Comentario" placement="top">
                        <i class="icon-message f-18 text-general mx-2 cr-pointer" @click="comentarioEntrega(data)" />
                    </el-tooltip>
                </div>
                <div class="col-xl-4">
                    <div v-if="data.editable == 0" class="row mx-0 j-center">
                        <div class="bg-white shadow br-12 py-2 mx-1 px-3 text-general cr-pointer" @click="devolverEntrega(data)">
                            Devolver
                        </div>
                        <div class="bg-white shadow br-12 py-2 mx-1 px-3 cr-pointer" @click="calificarEntrega(data)">
                            Calificar
                        </div>
                    </div>
                    <div v-else class="row mx-0 j-center">
                        <div class="devuelta py-2 px-2 br-12 text-center">
                            Devuelta {{ formatearFecha(data.updated_at) }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row mx-0 justify-content-end">
                <div class="col-auto">
                    <el-pagination
                    :total="6"
                    class="paginar-edit"
                    layout="prev, pager, next" 
                    />
                </div>
            </div> -->
            <!-- Partials -->
        </template>
        <!-- <visor-pdf ref="vPdf" /> -->
        <modal-video ref="modal_video" />
        <modal-imagen ref="ver_archivo" />

        <modal-comentario-entrega ref="ComentarioEntrega" :data="currentEntrega" />
        <modal-devolver-entrega :id="currentEntrega.id_hecha" ref="DevolverEntrega" @updated="updateEntrega()" />
        <modal-calificar-entrega ref="CalificarEntrega" :data="currentEntrega" @updated="updateList()" />
    </section>
</template>

<script>
import { entregasHechas } from '~/services/cursos/entregas_hechas';
import { mapActions } from 'vuex'
const vuexRoute = 'responsable/entregas'
export default {
    name: 'Entregas',
    components:{
        modalComentarioEntrega: () => import('./partials/modalComentarioEntrega'),
        modalDevolverEntrega: () => import('./partials/modalDevolverEntrega'),
        modalCalificarEntrega: () => import('./partials/modalCalificarEntrega')
    },
    data(){
        return {
            busqueda: '',
            entregas: [],
            idCurso: this.$route.params.id_curso,
            loading: true,
            currentEntrega: {}
        }
    },
    computed: {
        entregasFiltered(){
            return this.entregas.filter(el => this.busqueda == '' || el.nombre.toLowerCase().includes(this.busqueda.toLowerCase()))
        }
    },
    mounted(){
        this.listar()
    },
    methods: {
        ...mapActions({
            fetchCursos: `${vuexRoute}/fetchCursos`,
        }),
        comentarioEntrega(data){
            this.currentEntrega = {...data}
            this.$refs.ComentarioEntrega.toggle()
        },
        devolverEntrega(data){
            this.currentEntrega = {...data}
            this.$refs.DevolverEntrega.toggle();
        },
        calificarEntrega(data){
            this.currentEntrega = {...data}
            this.$refs.CalificarEntrega.toggle()
        },
        async listar(){
            try {
                this.loading = true
                const { data } = await entregasHechas.listar(this.idCurso)
                this.entregas = data.data
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        updateEntrega(){
            let entregas = [...this.entregas]
            let index = entregas.findIndex(el => el.id_hecha == this.currentEntrega.id_hecha)
            entregas[index].editable = 1;
            entregas[index].updated_at = new Date();
            this.currentEntrega = {}
            this.entregas = [...entregas]
        },
        updateList(){
            let entregas = this.entregas.filter(el => el.id_hecha != this.currentEntrega.id_hecha)
            this.entregas = [...entregas]
            this.fetchCursos(this.idCurso)
        },
        async descargarArchivos({ id_hecha: id, titulo }){
            try {
                this.loading = true
                const { data } = await entregasHechas.descargarArchivos(id)
                this.descargarZip(data, `Adjuntos - ${titulo}`)
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async setVisualizar({archivo, archivo_firmado, nombre}){
            if (this.file_type(archivo) == "application/pdf"){
                window.open(`/visor?nombre=${archivo_firmado}`,'_blank')
            }else if(this.file_type(archivo) == "video/*"){
                this.$refs.modal_video.ModalVideo(archivo_firmado)
            }else if(this.file_type(archivo) == "image/*"){
                this.$refs.ver_archivo.toggle(archivo_firmado)
            }else{
                await this.downloadURI(archivo, nombre);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.etiqueta{
    background-color: #F8F8F8 !important;
    color: #ACACAC !important;
    font-size: 15px;
    max-width: 180px !important;
    border-radius: 12px !important;
}
.devuelta{
    background: #F8F8F8;
    color: #AEAEAE;
    border: 1px solid #F8F8F8;
}
</style>
